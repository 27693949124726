:root {
  --color-blue: #150f96;
  --color-primary: #3F51B5;
  --color-warning: #F44A3E;
}

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
[data-bs-theme="light"] {
    --color-light-gray: #BCBCBC;
    --color-lighter-gray: #e8e8e8;
    --header-background: #fff;
    --breacrumb-bottom-border: #e7e7e7;
    --header-color-icon: inherit;
    --footer-background: #4B4E53;
    --body-padding: 20px;
    --upload-file-background: #f4f4f4;
    --upload-file-background-hover: #c3c3c3;
    --loading-bar-background: #fff;
    --scrollbar-background: #e8e8e8;
    --dropdown-background: #f4f4f4;
    --dropdown-background-hover: #d7d7d7;
    --text-color: #151515;
    --input-background: #fff;
    --ch-file-failed: #F44336;
    --ch-file-uploaded: #3d51b5;
}
[data-bs-theme="dark"] {
    --color-light-gray: #BCBCBC;
    --color-lighter-gray: #e8e8e8;
    --header-background: #1B1F22;
    --breacrumb-bottom-border: #1B1F22;
    --header-color-icon: #e8e8e8;
    --footer-background: #1B1F22;
    --body-padding: 20px;
    --upload-file-background: #1B1F22;
    --upload-file-background-hover: #000;
    --loading-bar-background: #1B1F22;
    --scrollbar-background: #000;
    --dropdown-background: #000;
    --dropdown-background-hover: #1B1F22;
    --text-color: #fff;
    --input-background: #212529;
    --ch-file-failed: #F44336;
    --ch-file-uploaded: #3d51b5;
}
[data-bs-theme="dark"] img[src*='.svg'] {
    filter: invert(1)
}
[data-primary-color="#000"] { --color-primary: #000; }
[data-primary-color="#4B4E53"] { --color-primary: #4B4E53; }
[data-primary-color="#100092"] { --color-primary: #100092; }
[data-primary-color="#43BDD4"] { --color-primary: #43BDD4; }
[data-primary-color="#87CB30"] { --color-primary: #87CB30; }
[data-primary-color="#6820C1"] { --color-primary: #6820C1; }
[data-primary-color="#D9371E"] { --color-primary: #D9371E; }
[data-primary-color="#F33196"] { --color-primary: #F33196; }
[data-primary-color="#F57220"] { --color-primary: #F57220; }
[data-primary-color="#F9BB37"] { --color-primary: #F9BB37; }

/* Typography */
body                                    { font-family: 'Lato', sans-serif; }
h1, h2, h3, h4, h5, h6                  { font-family: 'Georgia', sans-serif; }
.color-selected                         { color: var(--color-primary); }


/* body elements */
.main-body                              { padding: 0 var(--body-padding); min-height: calc(100vh - 197px); }
/* drop-down */
.dd-button                              { position: relative; }
.dd-button:before                       { content: ''; position: absolute; bottom: -2px; height: 2px; width: 100%; left:0; right:0; transition: all 0.3s ease-in-out; }
.dd-button.no-underscore:before         { content: none; }
.dd-button:hover:before                 { background-color: #000;}
.dd-button[aria-expanded="true"]:before { background-color: #000; }
.dropdown-menu                          { padding: 0; margin-top: 8px !important; border-radius: 2px; background-color: var(--dropdown-background); border: none; box-shadow: 0 2px 4px rgba(0,0,0,0.275); }
.dropdown-item                          { transition: all 0.3s ease-in-out; padding: 8px 12px !important; }
.dropdown-item:hover,
.dropdown-item:focus                    { background-color: var(--dropdown-background-hover); }

/* buttons */
.cb-button                              { margin: auto; display: inline-block; border: solid 1px #000; padding: 10px 40px; font-weight: 600; transition: all 0.3s ease-in-out; background-color: transparent; color: #000; text-decoration: none;}
.cb-button:hover                        { background-color: #000; color: #fff; }
[data-bs-theme='dark'] .cb-button       { background-color: #000; color: #fff; border: solid 1px #fff; }
[data-bs-theme='dark'] .cb-button:hover { background-color: #fff; color: #000; }
.cb-button-stroke                       { padding: 8px 12px; transition: all 0.3s ease-in-out; }
.cb-button-stroke.primary               { border: solid 1px var(--color-primary); color: var(--color-primary); }
.cb-button-stroke.primary:hover         { background-color: var(--color-primary); color: #fff; }

/* general fixes */
.main-contents                  { margin: 10vh 0; }
@media (max-width: 768px) {
  .main-contents                { margin-top: 120px; }
}

/* page header area */
.page-header-area                       {padding: 10px 0 9px 0; border-bottom: solid 1px var(--breacrumb-bottom-border); margin-bottom:30px; transition: all 0.3s ease-in-out; }
.back-to-router-link                    {position:relative; display:inline-block;}
.back-to-router-link a                  {text-decoration: none;}
.back-to-router-link .text              {position:relative; left:0; color:var(--color-primary); text-transform: uppercase; font-size: 12px; transition: all 0.3s ease-in-out;}
.back-to-router-link img                {position:absolute; top:8px; left:9px; transition: all 0.3s ease-in-out; opacity:0; }
.back-to-router-link:hover .text        {left: 14px; color:var(--color-primary);}
.back-to-router-link:hover img          {opacity:1; left:0px; color:var(--color-primary)}
.page-title                             {padding-top: 5px;}
.sticky-top                             {z-index: 9; }

/* table classes */
.light-yellow-row                                 { background-color:#fffacd;}
.green-check-icon                                 { position:relative; top:-1px; margin: 0 5px;}
.assignment-detail-table tbody tr td              { height:57px; vertical-align: middle;}
.assignment-detail-table tbody tr td:first-child  { width: 30px; }
.assignment-detail-table tbody tr td:nth-child(2) { width: 50%; }

/* sweet alert classes */
div:where(.swal2-container)                       { z-index: 555 !important; }

/* mat dialog classes */
.mat-mdc-dialog-container .mdc-dialog__surface    { padding: 24px; }
/* disable fields label color */
.mdc-text-field--disabled .mdc-floating-label     { color: #757575 !important; }
.mat-mdc-select-disabled .mat-mdc-select-value    { color: #757575 !important; }
.mdc-text-field--disabled .mdc-text-field__input  { color: #757575 !important; }
.mdc-text-field--disabled                         { background-color: #f1f1f1 !important; }

/* accordion */
.accordion-icon                                   {width: 20px; height: 20px; position: relative; top: -8px; left: 3px; }

/* tabs */
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--color-primary);
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: var(--text-color);
}

/* welcome */
.color-block-wrapper .color-block-bottom                {background-color: transparent !important;}
.mat-expansion-panel .mat-expansion-panel-header        {background-color: none !important; border-bottom: solid 1px var(--breacrumb-bottom-border) !important; }
.mat-expansion-panel .mat-expansion-panel-header:hover  {background-color:rgba(0,0,0,0.01) !important;}
.mat-expansion-panel .mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel .mat-expansion-panel-header.mat-expanded:hover          {background-color: #fff;}
.mat-expansion-panel                                    { color: var(--text-color) !important;}

/* input fields */
.mat-mdc-checkbox .mdc-form-field                                             {color:var(--text-color)}
/* .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  background-color: var(--text-color) !important;
} */

/* angular forms styles to match earlier versions */
.mdc-text-field                                                               { background-color: var(--input-background); }
.mdc-button__label                                                            { text-transform: uppercase; /* color: var(--color-primary) */ }
.mdc-button__label .bi                                                        { color: #fff; }
.mat-mdc-form-field-hint-wrapper, 
.mat-mdc-form-field-error-wrapper                                             { padding: 0; }

/* .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label            { color: var(--color-primary)}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label   { color: var(--color-primary)}
.mat-mdc-select-value                                                         { color: var(--text-color) !important}  */

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--color-primary) !important;
}
.mat-mdc-form-field-icon-suffix, [dir=rtl] .mat-mdc-form-field-icon-prefix {
  padding: 0 10px 0 4px !important;
}

/* icons */
.icon-xs                                        {width:12px; height:12px; position:relative; top:-1px;}
.icon-sm                                        {width:14px; height:14px; position:relative; top:-1px;}
.icon-white                                     {filter: invert(1)}

/* spinning loader styles */
#showLoader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(to top, #e2ebf0 0%, #fff 100%);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

#loading-spinner {
  text-align: center;
  display: block;
  width: 100%;
  position: relative;
  top: 33vh;
}

#loading-spinner .loading-text {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
#loading-spinner .loading-text span {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  display:block;
  padding: 20px 0 10px 0;
}
#loading-spinner .loading-chubb-logo {
  width: 180px;
  padding: 5px 0 15px 0;
}

div.spinner {
  -moz-animation: rotate 10s infinite linear;
  -webkit-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
  display: block;
  margin: auto;
  width: 142px;
  height: 142px;
}

div.spinner i {
  -moz-animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
  -webkit-animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
  animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
  -moz-transform-origin: 50% 100% 0;
  -webkit-transform-origin: 50% 100% 0;
  transform-origin: 50% 100% 0;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  border: solid 6px transparent;
  border-bottom: none;
}

div.spinner i:nth-child(1) {
  -moz-animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  width: 44px;
  height: 22px;
  margin-top: -22px;
  margin-left: -22px;
  border-color: #2D2F86;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
}

div.spinner i:nth-child(2) {
  -moz-animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  width: 58px;
  height: 29px;
  margin-top: -29px;
  margin-left: -29px;
  border-color: #1CBED5;
  border-top-left-radius: 42px;
  border-top-right-radius: 42px;
}

div.spinner i:nth-child(3) {
  -moz-animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  width: 72px;
  height: 36px;
  margin-top: -36px;
  margin-left: -36px;
  border-color: #7CC142;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
}

div.spinner i:nth-child(4) {
  -moz-animation-timing-function: cubic-bezier(0.09, 1.2, 0.48, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 1.2, 0.48, 0.03);
  animation-timing-function: cubic-bezier(0.09, 1.2, 0.48, 0.03);
  width: 86px;
  height: 43px;
  margin-top: -43px;
  margin-left: -43px;
  border-color: #FDB419;
  border-top-left-radius: 54px;
  border-top-right-radius: 54px;
}

div.spinner i:nth-child(5) {
  -moz-animation-timing-function: cubic-bezier(0.09, 1.5, 0.6, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 1.5, 0.6, 0.03);
  animation-timing-function: cubic-bezier(0.09, 1.5, 0.6, 0.03);
  width: 100px;
  height: 50px;
  margin-top: -50px;
  margin-left: -50px;
  border-color: #F16623;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
}

div.spinner i:nth-child(6) {
  -moz-animation-timing-function: cubic-bezier(0.09, 1.8, 0.72, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 1.8, 0.72, 0.03);
  animation-timing-function: cubic-bezier(0.09, 1.8, 0.72, 0.03);
  width: 114px;
  height: 57px;
  margin-top: -57px;
  margin-left: -57px;
  border-color: #EE382D;
  border-top-left-radius: 66px;
  border-top-right-radius: 66px;
}

div.spinner i:nth-child(7) {
  -moz-animation-timing-function: cubic-bezier(0.09, 2.1, 0.84, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 2.1, 0.84, 0.03);
  animation-timing-function: cubic-bezier(0.09, 2.1, 0.84, 0.03);
  width: 128px;
  height: 64px;
  margin-top: -64px;
  margin-left: -64px;
  border-color: #EC248F;
  border-top-left-radius: 72px;
  border-top-right-radius: 72px;
}

div.spinner i:nth-child(8) {
  -moz-animation-timing-function: cubic-bezier(0.09, 2.4, 0.96, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 2.4, 0.96, 0.03);
  animation-timing-function: cubic-bezier(0.09, 2.4, 0.96, 0.03);
  width: 142px;
  height: 71px;
  margin-top: -71px;
  margin-left: -71px;
  border-color: #5d3191;
  border-top-left-radius: 78px;
  border-top-right-radius: 78px;
}

@-moz-keyframes rotate {
  to {
      -moz-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}