// Project Theme
@use '@angular/material' as mat;
@include mat.core();
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
// Project SCSS Import
@import "assets/scss/variables";
@import "assets/scss/mixins";
@import "assets/scss/utilities";

// Loukas
// fixes for deprecated components
app-dialog {
  padding: 15px !important;
  display: block !important;
}

//#######################################//
// ********** Universal Start ********** //
//#######################################//

img {
  height: auto;
  max-width: 100%;
}

button {
  text-transform: uppercase;
  font-weight: bold;

  &:focus {
    outline: none !important;
  }
}

.input-area-small {
  height: 40px;
  line-height: 20px;
}

.input-area-big {
  height: 150px;
  line-height: 20px;
}
// css for column  filter in table starts
.top-wrapper {
  align-content: space-between;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 75px;
  padding: 1px 2px 2px 1px;
  width: 100%;
}

.column-search-input-wrapper {
  align-items: center;
  display: flex;
}

.column-search-input {
  border-bottom: 1px solid black;
  color: #150f96;
  font-size: 14px;
  font-weight: normal;
}

.filter-column-icons {
  cursor: pointer;
  font-size: 16px;
  margin-right: 0px;
  padding-top: 4px;
}
// css for column filter in table ends

// css for sticky column in table start
.first-col {
  left: 0;
  max-width: 90px; //to solve the width problem on search and sort
  min-width: 90px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}

.second-col {
  left: 90px;
  min-width: 150px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}

.third-col {
  align-content: flex-start;
  left: 240px;
  min-width: 180px;
  overflow-wrap: break-word;
  position: -webkit-sticky;
  position: sticky;
  word-wrap: break-word;
  z-index: 5;
}

.fourth-column {
  border-right: 1px black;
  left: 420px;
  min-width: 150px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}

.tbl-second-col {
  left: 90px;
  min-width: 200px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}

.tbl-third-col {
  left: 190px;
  min-width: 170px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}

.liteV-second-col {
  border-right: 1px black;
  left: 90px;
  min-width: 190px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}

.wrap-text-cell {
  align-content: flex-start;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
// css for sticky column in table ends

.big-btn,
.small-btn,
.action-btn {
  &.green-btn {
    button {
      background: $green !important;
    }

    button[disabled] {
      background: $gray-500 !important;
      color: $white !important;
    }
  }

  &.red-btn {
    button {
      background: $red !important;
    }
  }

  &.white-btn {
    button {
      background: $white !important;
      border: 1px solid $blue !important;
      color: $theme-primary !important;
    }
  }

  &.simple-btn {
    button {
      background: $white !important;
      border: none !important;
      color: $theme-primary !important;
      box-shadow: none !important;
    }
  }

  &.circle-green-btn {
    button {
      background: $green !important;
      border: none !important;
      color: $white !important;
      box-shadow: none !important;
    }
  }
}

.big-btn {
  button {
    padding: 7px 15px;
  }
}

a {
  cursor: pointer;

  &.no-outline {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.custom-tooltip {
  cursor: help;
  position: relative;

  em {
    background: $red;
    border-radius: 5px;
    color: $white;
    display: block;
    font-style: normal;
    margin-left: -999em;
    padding: 8px 10px;
    position: absolute;
    white-space: pre-wrap;
    width: 390px;
  }

  &:hover {
    em {
      z-index: 9999;
      display: block;
      margin-left: 0;
      position: absolute;
      left: 450px;
      top: -12px;
    }
  }
}

// Container Start
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 95%;
    max-width: 95%;
  }
}
// Container End

// Extra Color Start
.blue {
  color: $blue;
}

.turquoise {
  color: $turquoise;
}

.orange {
  color: $orange;
}

.fuschia {
  color: $fuschia;
}

.yellow {
  color: #fffacd;
}

.purple {
  color: $purple;
}

.purple-lite {
  color: $purple-lite;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.gray {
  color: $gray;
}

.dark-red {
  color: #f44336;
}

.lite-cyan {
  background: #f8feff;
}

.lite-orange {
  background: #fffdf9;
}

.lite-green {
  background: #f9fff1;
}

.lite-pink {
  background: #fffcfe;
}

.brown {
  color: #f0d3d3;
}
// Extra Color End

// Font Size Start
.fs-14 {
  font-size: 14px;
}
// Font Size End

// Spacer Start
.spacer {
  box-sizing: border-box;
  overflow: hidden;

  &--5 {
    height: 5px;
  }

  &--10 {
    height: 10px;
  }

  &--20 {
    height: 20px;
  }

  &--30 {
    height: 30px;
  }

  &--40 {
    height: 40px;
  }

  &--50 {
    height: 50px;
  }

  &--70 {
    height: 70px;
  }
}
// Spacer End

// Notification Start
.notification {
  box-sizing: border-box;
  overflow: hidden;
  color: $gray-600;

  &--success {
    background: $theme-success;
    color: $white;
    border-radius: 20px;
    padding: 0 10px 2px;
  }

  &--warn {
    background: $theme-warning;
    color: $white;
    border-radius: 20px;
    padding: 0 10px 2px;
  }

  &--danger {
    background: $theme-danger;
    color: $white;
    border-radius: 20px;
    padding: 0 10px 2px;
  }

  &--info {
    background: $theme-info;
    color: $white;
    border-radius: 20px;
    padding: 0 10px 2px;
  }

  &--disable {
    background: $theme-light;
    color: $white;
    border-radius: 10px;
    padding: 3px 5px;
  }
}

.no-result {
  color: $red;
  font-size: 16px;
  font-weight: 600;
}
// Notification End

// Material Start
.full-width {
  width: 100%;
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    background: $white !important;
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline {
      background: $gray-400 !important;
    }
  }
}
// Material End

// Button Start
.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: transparent !important;
  color: $theme-primary !important;
  border: 1px solid $theme-primary !important;
}
// Button End

// Page Start
.page-title {
  font-size: 22px;
  line-height: 30px;
  text-transform: uppercase;
}

.float-btn {
  position: absolute;
  z-index: 999;

  &.right {
    bottom: 0px;
    right: 0px;
  }
}

.green-btn {
  background: $green !important;
}
// Page End

// Accordion Start
.custom-accordion {
  .green {
    color: $green;
  }

  .purple {
    color: $purple;
  }

  .dark-red {
    color: $red;
  }

  .fuschia {
    color: $fuschia;
  }

  .turquoise {
    color: $turquoise;
  }

  .orange {
    color: $orange;
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
  }

  &__green {
    .mat-expansion-panel-body {
      background: #f7ffec !important;
      padding: 16px !important;
    }
  }

  &__purple {
    .mat-expansion-panel-body {
      background: #fcf4ff !important;
      padding: 16px !important;
    }
  }

  &__dark-red {
    .mat-expansion-panel-body {
      background: #fff9f9 !important;
      padding: 16px !important;
    }
  }

  &__fuschia {
    .mat-expansion-panel-body {
      background: #fff6fc !important;
      padding: 16px !important;
    }
  }

  &__turquoise {
    .mat-expansion-panel-body {
      background: #f5feff !important;
      padding: 16px !important;
    }
  }

  &__orange {
    .mat-expansion-panel-body {
      background: #fffdf9 !important;
      padding: 16px !important;
    }
  }
}

.search-accordion {
  .mat-expansion-panel-body {
    padding-bottom: 0 !important;
  }
}

.mat-expansion-panel {
  background: $gray-50;
  border-radius: 0 !important;
  box-shadow: none !important;
  overflow: inherit !important;

  .mat-expansion-panel-header {
    background: $white;
    border-bottom: 1px solid $gray-500;
    height: 35px !important;
    padding: 0 !important;

    &:hover {
      background: $white;
      border-bottom: 1px solid $gray-500;
    }

    .mat-expansion-indicator {
      &::after {
        content: none;
      }
    }

    .mat-content {
      .mat-expansion-panel-header-title,
      .mat-expansion-panel-header-description {
        padding: 0 40px !important;
        text-transform: uppercase;
      }

      .mat-icon {
        margin: 0 20px 0 0 !important;
        position: absolute !important;
      }
    }
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 25px;
    }
  }
}
// Accordion End

/*.mat-radio-button ~ .mat-radio-button {
  margin-left: 630px;
}*/

.mat-radio-group {
  display: flex;
  width: 54%;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-direction: column;

    .mat-radio-button {
      margin-left: 0px !important;
    }
  }
}

.mat-tab-body-content {
  height: 130px !important;
}

.search-column-radio-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  .mat-radio-container {
    top: -38px;
  }

  .mat-radio-label-content {
    width: 100%;
  }
}

// Table Start
.table-container-section {
  position: relative;

  .table-container {
    box-shadow: none;
    position: relative;
    max-height: 480px;
    overflow: auto;
    background: $white;

    table {
      width: 100%;
    }

    table td,
    table th {
      padding: 2px 0;
    }

    .mat-table-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }

    .mat-header-row {
      align-content: flex-start;
      align-items: start;
      justify-content: flex-start;
      position: sticky;
      top: 0;
      z-index: 100;
      padding: 10px 0 0;
      border-bottom-width: 10px !important;
      background: $gray-100;
    }
    // .mat-header-cell:hover::after,
    // .mat-cell:hover::after {
    //   background-color: rgba(255, 255, 0, 0.5);
    //   content: "\00a0";
    //   height: 10000px;
    //   left: 0;
    //   position: absolute;
    //   top: -5000px;
    //   width: 100%;
    // }
    .mat-footer-row,
    .mat-row {
      align-content: flex-start;
      align-items: stretch;
      background: $white;
      justify-content: flex-start;

      &:hover {
        background: #d7fbff;
      }

      &:nth-child(even) {
        // background: $gray-50;
        &:hover {
          background: #d7fbff;
        }
      }
    }

    .mat-header-cell {
      align-content: flex-start;
      align-items: start;
      color: $black;
      font-size: 9px;
      font-weight: 600;
      justify-content: flex-start;
      min-height: 80px;
      padding: 2px 2px;
      text-transform: uppercase;

      .header-cell {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        margin-bottom: 3px;
        min-height: 40px;
        //overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: break-spaces;
        overflow-x: visible !important;
        min-width: 67px;
      }
      // div {
      //   button {
      //     div {
      //       -webkit-box-orient: vertical;
      //       -webkit-line-clamp: 2;
      //       display: -webkit-box;
      //       overflow: hidden;
      //       text-overflow: ellipsis;
      //       div {
      //         &:first-child {
      //           -webkit-box-orient: vertical;
      //           -webkit-line-clamp: 2;
      //           display: -webkit-box;
      //           min-height: 40px;
      //           overflow: hidden;
      //           text-overflow: ellipsis;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    .mat-cell,
    .mat-footer-cell {
      align-items: start;
      padding: 2px 10px;
      font-size: 12px;
      word-break: break-word;
    }

    .mat-cell-radio {
      align-items: center !important;
    }

    .mat-sort-header-button {
      text-align: left;
      flex-direction: column;
      border-bottom: none !important;
    }

    .sticky-divider {
      border-right: 1px solid $gray-300 !important;
      left: 290px !important;
    }

    .sticky-divider-1stCol {
      border-right: 1px solid $gray-300 !important;
      left: 90px !important;
    }

    .sticky-divider-mgr {
      border-right: 1px solid $gray-300 !important;
      //left: 290px !important
    }
    // table cell width start
    .cellWidth-60 {
      max-width: 60px;
      min-width: 60px;
      width: 60px !important;
    }

    .cellWidth-80 {
      max-width: 80px;
      min-width: 80px;
      width: 80px;
    }

    .cellWidth-85 {
      max-width: 85px;
      min-width: 85px;
      width: 85px;
    }

    .cellWidth-90 {
      max-width: 90px;
      min-width: 90px;
      width: 90px;
    }

    .cellWidth-100 {
      max-width: 100px;
      min-width: 100px;
      width: 100px;
    }

    .cellWidth-110 {
      max-width: 110px;
      min-width: 110px;
      width: 110px;
    }

    .cellWidth-130 {
      max-width: 130px;
      min-width: 130px;
      width: 130px;
    }

    .cellWidth-140 {
      max-width: 140px;
      min-width: 140px;
      width: 140px;
    }

    .cellWidth-150 {
      max-width: 150px;
      min-width: 150px;
      width: 150px;
    }

    .cellWidth-155 {
      max-width: 155px;
      min-width: 155px;
      width: 155px;
    }

    .cellWidth-160 {
      max-width: 160px;
      min-width: 160px;
      width: 160px;
    }

    .cellWidth-170 {
      max-width: 170px;
      min-width: 170px;
      width: 170px;
    }

    .cellWidth-175 {
      max-width: 175px;
      min-width: 175px;
      width: 175px;
    }

    .cellWidth-180 {
      max-width: 180px;
      min-width: 180px;
      width: 180px;
    }

    .cellWidth-190 {
      max-width: 190px;
      min-width: 190px;
      width: 190px;
    }

    .cellWidth-200 {
      max-width: 200px;
      min-width: 200px;
      width: 200px;
    }

    .cellWidth-210 {
      max-width: 210px;
      min-width: 210px;
      width: 210px;
    }

    .cellWidth-250 {
      max-width: 250px;
      min-width: 250px;
      width: 250px;
    }

    .cellWidth-260 {
      max-width: 260px;
      min-width: 260px;
      width: 260px;
    }

    .cellWidth-270 {
      max-width: 270px;
      min-width: 270px;
      width: 270px;
    }
    // table cell width ends
  }
}

.mat-table__wrapper .mat-table {
  min-width: auto !important;
  width: 100% !important;
  table-layout: fixed;

  @media (max-width: 992px) {
    display: block;
    overflow: scroll;
  }

  .mat-header-row {
    width: 100%;

    .mat-header-cell {
      color: $black;
      font-size: 12px;
      font-weight: 600;
      min-height: 80px;
      padding: 2px 10px;
      text-transform: uppercase;
    }
  }

  .mat-row {
    width: 100%;

    &:hover {
      background: #d7fbff;
    }

    &:nth-child(even) {
      &:hover {
        background: #d7fbff;
      }
    }

    .mat-cell {
      font-size: 12px;
      padding: 2px 10px;
    }
  }
  // .mat-column-{colum-name} {
  //     flex: 0 0 25% !important;
  //     min-width: 104px !important;
  // }
}
// Table End
// Dialog Start
.dialog-message {
  text-align: center;
  word-wrap: break-word;

  &__success-icon {
    height: 120px;
    margin: 20px 0;
    width: 120px;
  }

  &__message {
    color: $gray-900;
    font-size: 16px;
    // margin: 20px 0 40px;
    // min-height: 160px;
    text-align: left;
  }

  &__success {
    color: $green;
    font-size: 24px;
    margin: 20px 0 40px;
  }

  &__error {
    color: $red;
    font-size: 24px;
    margin: 20px 0 40px;
  }

  &__action-button {
    margin: 40px 0 20px;
  }
}
// Dialog End
// Stepper Progress Start
.progressbar {
  counter-reset: step;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  li {
    list-style-type: none;
    position: relative;
    text-align: center;
    width: 13%;

    @media (max-width: 992px) {
      width: 25% !important;
    }

    &:first-child {
      &:after {
        content: none;
      }
    }

    &:before {
      /* CSS for creating steper block before the li item */
      content: counter(step);
      counter-increment: step;
      height: 30px;
      width: 30px;
      line-height: 24px;
      border: 2px solid $gray-600;
      display: block;
      text-align: center;
      margin: 0 auto 10px;
      border-radius: 50%;
      background: $gray-600;
      color: $white;
    }

    &:after {
      /* CSS for creating horizontal line */
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $gray-600;
      top: 15px;
      left: -50%;
      z-index: -1;
    }

    &.active {
      color: $black;
      font-weight: 600;

      &:before {
        border-color: $green;
        background: $green;
      }
    }
  }

  li.active + li:after {
    background: $green;
  }
}
// Stepper Progress End
// Spinner Start
.spinner-div {
  display: flex;
  justify-content: center;
  // margin: 15px;
}
// Spinner End
// Loader Start

.loading-main {
  /*  background-color: #0000001f;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: grid;
  z-index: 1;*/

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray-200;
  z-index: $zindex-popover;
  opacity: 0.5;
  filter: alpha(opacity=30);
}

.loading-spinner {
  top: 48%;
  left: 48%;
  text-align: center;
  position: absolute;
  z-index: $zindex-popover;
}

.loading-spinner mat-spinner {
  align-self: end;
}
// Loader End
// Print Start
// @media print {
//   app-root > * {
//     display: none;
//   }
//   app-root app-print-layout {
//     display: block;
//   }
// }
// Print End
//#####################################//
// ********** Universal End ********** //
//#####################################//

////////////////////////////
// ++++ Footer Start ++++ //
////////////////////////////
.footer {
  border-top: 1px solid $gray-500;
  margin: 50px 0 0;
  padding: 20px 0;

  &__fixed {
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 999999;
  }

  &__logo {
    width: 100px;
  }
}
//////////////////////////
// ---- Footer End ---- //
//////////////////////////
/////////////////////////////////////
// ++++ Page :: Manager Start ++++ //
/////////////////////////////////////
.welcome {
  margin: 50px auto;
  width: 60%;

  &__card {
    h6 {
      font-weight: bold;
    }

    &__action {
      position: absolute !important;
      right: 10%;
      bottom: -27px;
    }
  }
}
/* cards */
.color-block-wrapper {
  margin-top: 40px;
  -webkit-box-shadow: 0px 15px 30px 0px rgba(124, 124, 124, 0.15);
  -moz-box-shadow: 0px 15px 30px 0px rgba(124, 124, 124, 0.15);
  box-shadow: 0px 15px 30px 0px rgba(124, 124, 124, 0.15);
  position: relative;

  .color-block {
    color: #fff;
    border-radius: 2px 2px 0 0;
    padding: 10px 20px;
    margin-top: 75px;
    position: relative;

    &:before {
      content: "";
      border-radius: 2px 2px 0 0;
      width: calc(100% - 12px);
      height: 6px;
      position: absolute;
      top: -6px;
      left: 6px;
      opacity: 0.4;
      z-index: 20;
    }

    &:after {
      content: "";
      border-radius: 2px 2px 0 0;
      width: calc(100% - 24px);
      height: 12px;
      position: absolute;
      top: -12px;
      left: 12px;
      opacity: 0.3;
      z-index: 10;
    }
    /* cards colors */
    &.color-block-lblue {
      background: #005bb7;
      z-index: 30;

      &:before {
        background: #0064ca;
      }

      &:after {
        background: #007fff;
      }
    }

    &.color-block-dblue {
      background: #004387;

      &:before {
        background: #0c457e;
      }

      &:after {
        background: #135ba5;
      }
    }

    &.color-block-green {
      background: #49ac01;

      &:before {
        background: #49ac01;
      }

      &:after {
        background: #82c600;
        opacity: 0.4;
      }
    }
  }

  .color-block-bottom {
    background: #fff;
    padding: 30px 40px;
  }

  .color-block-text {
    letter-spacing: 0.5px;
    font-size: 20px;
  }
}
///////////////////////////////////
// ---- Page :: Manager End ---- //
///////////////////////////////////
/////////////////////////////////////
// ++++ Page :: Handler Start ++++ //
/////////////////////////////////////
.data {
  .data__row,
  .data__row-green,
  .data__row-purple,
  .data__row-dark-red,
  .data__row-brown,
  .data__row-yellow,
  .data__row-fuschia {
    .data__col {
      background: $white;
      border-bottom: 1px solid $gray-500;
      padding: 12px 15px;
      min-height: 45px;

      .green-tick {
        background: url(./assets/images/green-small-check.png) no-repeat;
        display: inline-block;
        height: 12px;
        width: 12px;
      }
    }
  }

  .data__row-green {
    .data__col {
      &:nth-child(8n + 5),
      &:nth-child(8n + 6),
      &:nth-child(8n + 7),
      &:nth-child(8n + 8) {
        background: #f7ffec !important;
      }
    }
  }

  .data__row-purple {
    .data__col {
      &:nth-child(8n + 5),
      &:nth-child(8n + 6),
      &:nth-child(8n + 7),
      &:nth-child(8n + 8) {
        background: #fcf4ff !important;
      }
    }
  }

  .data__row-dark-red {
    .data__col {
      &:nth-child(8n + 5),
      &:nth-child(8n + 6),
      &:nth-child(8n + 7),
      &:nth-child(8n + 8) {
        background: #fff9f9 !important;
      }
    }
  }

  .data__row-brown {
    .data__col {
      &:nth-child(8n + 5),
      &:nth-child(8n + 6),
      &:nth-child(8n + 7),
      &:nth-child(8n + 8) {
        background: #f0d3d3 !important;
      }
    }
  }

  .data__row-yellow {
    .data__col {
      &:nth-child(8n + 5),
      &:nth-child(8n + 6),
      &:nth-child(8n + 7),
      &:nth-child(8n + 8) {
        background: #fffacd !important;
      }
    }
  }

  .data__row-fuschia {
    .data__col {
      &:nth-child(8n + 5),
      &:nth-child(8n + 6),
      &:nth-child(8n + 7),
      &:nth-child(8n + 8) {
        background: #fff6fc !important;
      }
    }
  }
}

.conflict-check,
.pricing-schedule {
  background: rgba(255, 236, 194, 0.7);
  border-radius: 5px;
  padding: 15px 20px 6px;

  .headline {
    text-transform: uppercase;
    font-weight: 600;
  }
}

.align-right-7 {
  margin-right: 1%;
}

.vendor-assignment-instructions {
  .red-message {
    padding: 8px 10px;
    background: $red;
    border-radius: 5px;
    color: $white;
    margin: 0 0 10px;
  }

  .input-vendor-instructions {
    height: 150px;
    line-height: 20px;
  }
}

.attachment {
  color: $black;

  .disclaimer {
    color: $gray-600;
    font-size: 13px;
  }
}

/////view attachment - start

.view-attachments-success {
  height: auto !important;
  margin-bottom: 10px;
  background: lightgreen !important;
}

.view-attachments-pending {
  height: auto !important;
  margin-bottom: 10px;
  background: orange !important;
}

.view-attachments-failure {
  height: auto !important;
  margin-bottom: 10px;
  background: red !important;
}
.view-attachments {
height: -webkit-fill-available !important;
}

/////view attachment - end

.showVerticalScroll {
  overflow-y: scroll !important;
  max-height: 450px !important;
}

.columnspace {
  left: 90px !important;
  position: relative;
  font-weight: normal;
  z-index: 1;
}
///////////////////////////////////
// ---- Page :: Handler End ---- //
///////////////////////////////////
////////////////////////////////////
// ++++ Page :: Vendor Start ++++ //
////////////////////////////////////
//////////////////////////////////
// ---- Page :: Vendor End ---- //
//////////////////////////////////
//////////////////////////////////////////
// ---- Checkbox :: long text start---- //
//////////////////////////////////////////
// adds breaks to the mat-checkboxes with long labels
::ng-deep .mat-checkbox-layout {
  white-space: normal !important;
}
// rather than center the checkbox, put the checkbox in the first line
::ng-deep .mat-checkbox-inner-container {
  margin-top: 3px !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
}
/** keep checkbox on first line */
.mat-checkbox-inner-container {
  margin-top: 4px !important;
}

.mat-checkbox-label {
  display: block;
  word-break: break-word;
  word-wrap: break-word;
}
//////////////////////////////////////////
// ---- Checkbox :: long text end---- //
//////////////////////////////////////////

////////////////////////////////////////////////
//----Hand held device compatibility start----//
////////////////////////////////////////////////

@media (max-width: 992px) {
  .cdk-global-scrollblock {
    .cdk-global-overlay-wrapper {
      .cdk-overlay-pane {
        width: 80vw !important;
      }
    }
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .cdk-global-scrollblock {
    .cdk-global-overlay-wrapper {
      .cdk-overlay-pane {
        height: 65% !important;
        overflow: scroll;
        pointer-events: initial !important;
      }
    }
  }

  .cdk-overlay-container {
    .mat-select-panel-wrap {
      .mat-select-panel {
        margin-top: 10px;
      }
    }
  }
}

//////////////////////////////////////////////
//----Hand held device compatibility end----//
//////////////////////////////////////////////

//Panel dropdown height
.mat-autocomplete-panel {
  max-height: 231px !important;
}
