.float-left {
  @include float-left;
}

.float-right {
  @include float-right;
}

.float-none {
  @include float-none;
}
